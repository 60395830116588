import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Congratulations, you made a Twitter bot! Now, you need actionable conversational analytics so that you can see how your bot is performing and improve its responses to increase engagement and retention.`}</p>
    <p>{`Get started by following the four steps below.`}</p>
    <h4>{`Step 1: Create a Twitter bot.`}</h4>
    <h4>{`Step 2: Create a `}<a parentName="h4" {...{
        "href": "https://www.dashbot.io?utm_source=twittercodeless&utm_medium=medium&utm_content=signup"
      }}>{`Dashbot account`}</a>{` (for free!) and add your Twitter bot.`}</h4>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/08/07115458/0531f-1mq93xx3xvit-dzi9ufewow.png)
    </figure>
#### Step 3: Select “**Connect Bot Directly to Twitter**” and authorize Dashbot.
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/08/07115457/0df0e-1yc6gpm_oehbh3gfusr-iyg.png)
    </figure>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/08/07115456/9ca7d-1rzzhmwrgwnkdnruxtx4ena.png)
    </figure>#### Step 4: Send your bot a message and check outLive Transcripts to verify
that your data is sending!
    <h4>{`Watch the tutorial here:`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="900" src="https://www.youtube.com/embed/hy5-m32XqVE?feature=oembed" width="1200"></iframe>
    <p>{`Need help `}<a parentName="p" {...{
        "href": "https://www.dashbot.io?utm_source=twittercodeless&utm_medium=medium&utm_content=signup"
      }}>{`integrating conversational analytics`}</a>{` for your Twitter bot? Shoot us a message at `}<strong parentName="p"><a parentName="strong" {...{
          "href": "mailto:contact@dashbot.io"
        }}>{`contact@dashbot.io`}</a></strong>{` and we’ll help you out!`}</p>
    <hr></hr>
    <h3>{`About Dashbot`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`You can view detailed analytics `}{`&`}{` competitive market metrics, analyze conversation transcripts, track message funnels, optimize referral campaigns, understand sentiment `}{`&`}{` demographics, and add human-in-the-loop to your bot — all in real time.`}</p>
    <p>{`We natively support Facebook Messenger, Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      